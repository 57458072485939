import React from "react";
import { Fragment } from "react";

export default function AddChecklistHeader() {
  return (
    <Fragment>
      <div className="col-sm-12">
        <h2>Add Checklist</h2>
      </div>
    </Fragment>
  );
}
