import React from "react";
import { Fragment } from "react";

export default function PaymentMethodHeader() {
  return (
    <Fragment>
        <h2>QuickBooks Online Integration</h2>
    </Fragment>
  );
}
