import React, { Fragment } from "react";
import Map from "../../assets/img/map.png";
import GoogleMap from "./GoogleMap";
function Locationonmap() {
  return (
    <Fragment>
     <GoogleMap />
    </Fragment>
  );
}

export default Locationonmap;
