import React from "react";
import { Fragment } from "react";

export default function ServiceLogDetailHeader() {
  return (
    <Fragment>
      <div className="row customers">
        <div className="col-sm-5 ">
          <h2>Customer Workorder History</h2>
        </div>
      </div>
    </Fragment>
  );
}
