import React from 'react'
import { Fragment } from 'react'
import ForgetPasswordData from '../components/Login/ForgetPassword'

function ForgetPassword() {
  return (
    <Fragment>
        <ForgetPasswordData/>
    </Fragment>
  )
}

export default ForgetPassword