import React, { useEffect, useState, useRef } from "react";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";
import { useSelector } from "react-redux";
import { FaMapMarkerAlt } from "react-icons/fa";
import { renderToString } from "react-dom/server";

function GoogleMap({ google, Techniciandata }) {
  const { data: activeServicedashboard } = useSelector(
    (state) => state.activeServicedashboard
  );
  const { data: ZoomToMap } = useSelector((state) => state.ZoomToMap);

  const [allRoutes, setAllRoutes] = useState([]);
  const [ToggleRoute, setToggleRoute] = useState([]);
  const [activeMarker, setActiveMarker] = useState(null);
  const [zoom, setZoom] = useState(6);
  const [originalCoordinates, setOriginalCoordinates] = useState({
    lat: 0,
    lng: 0,
  });
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [activeButton, setActiveButton] = useState("Selected");

  const mapRef = useRef(null);

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.map;
    }
  }, []);

  useEffect(() => {
    if (Techniciandata && Techniciandata.RouteAssignmentTechnician) {
      const technicianRoutes = Techniciandata.RouteAssignmentTechnician.map(
        (item) => ({
          lat: parseFloat(item.RouteAssignmentWaterBody.servicelat),
          lng: parseFloat(item.RouteAssignmentWaterBody.servicelong),
          name: item.RouteAssignmentWaterBody.customer_name,
          tech_color_code: Techniciandata.color_code,
          totalPool: item.RouteAssignmentWaterBody.name,
          completedPools: "",
          skippedcount: "",
          totaldistance: "",
          totaltime: "",
          last_name: "",
          position: item.RouteAssignmentWaterBody.position, // Add position here
        })
      );
      setToggleRoute(technicianRoutes);
      setZoom(10);
    }
    onClose(); // Close InfoWindow on data change
  }, [Techniciandata]);

  useEffect(() => {
    if (ZoomToMap && ZoomToMap.RouteAssignmentWaterBody) {
      setOriginalCoordinates({
        lat: parseFloat(ZoomToMap.RouteAssignmentWaterBody.servicelat),
        lng: parseFloat(ZoomToMap.RouteAssignmentWaterBody.servicelong),
      });
      setZoom(15);
    }
  }, [ZoomToMap, activeServicedashboard]);
  useEffect(() => {
    if (activeServicedashboard && activeServicedashboard.data) {
      const coordinatesData = activeServicedashboard.data.map((item) => ({
        lat: parseFloat(item.latitude),
        lng: parseFloat(item.longitude),
        name: item.first_name,
        tech_color_code: item.color_code,
        totalPool: item.TotalPools,
        completedPools: item.completedPools,
        skippedcount: item.skippedcount,
        totaldistance: item.totaldistance,
        totaltime: item.totaltime,
      }));

      if (coordinatesData.length > 0) {
        setOriginalCoordinates({
          lat: coordinatesData[0].lat,
          lng: coordinatesData[0].lng,
        });
      }
    }
  }, [activeServicedashboard]);

  useEffect(() => {
    const allPools =
      activeServicedashboard && activeServicedashboard.data
        ? activeServicedashboard.data.map((item) =>
            item.RouteAssignmentTechnician.map((elem) => ({
              lat: parseFloat(elem.RouteAssignmentWaterBody.servicelat),
              lng: parseFloat(elem.RouteAssignmentWaterBody.servicelong),
              name: item.first_name,
              tech_color_code: item.color_code,
              totalPool: item.TotalPools,
              completedPools: item.completedPools,
              skippedcount: item.skippedcount,
              totaldistance: item.totaldistance,
              totaltime: item.totaltime,
              last_name: item.last_name,
            }))
          )
        : [];

    setAllRoutes(allPools.flat());
  }, [activeServicedashboard]);

  const onMarkerClick = (props, marker) => {
    setActiveMarker(marker);
    setSelectedPlace(props);
  };

  const onClose = () => {
    if (activeMarker) {
      setActiveMarker(null);
      setSelectedPlace(null);
    }
  };

  const SelectedRoute = () => {
    setActiveButton("Selected");
    setZoom(8);
    setToggleRoute(
      Techniciandata?.RouteAssignmentTechnician?.map((item) => ({
        lat: parseFloat(item.RouteAssignmentWaterBody.servicelat),
        lng: parseFloat(item.RouteAssignmentWaterBody.servicelong),
        name: Techniciandata.first_name,
        tech_color_code: Techniciandata.color_code,
        totalPool: item.name,
        completedPools: item.name,
        skippedcount: item.name,
        totaldistance: item.name,
        totaltime: item.name,
        last_name: item.name,
      }))
    );
  };
  console.log(ToggleRoute, "<=======ToggleRoute");
  console.log(Techniciandata, "<=======Techniciandata");
  const AllRoute = () => {
    setActiveButton("All");
    setToggleRoute(allRoutes);

    if (mapRef.current) {
      const map = mapRef.current.map;

      const bounds = new google.maps.LatLngBounds();

      allRoutes.forEach((route) => {
        bounds.extend(new google.maps.LatLng(route.lat, route.lng));
      });

      map.fitBounds(bounds);
    }
  };
  const metersToMiles = (kilometers) => {
    const conversionFactor = 0.621371;
    return kilometers * conversionFactor;
  };

  const secondsToMinutes = (seconds) => {
    return seconds / 60;
  };

  return (
    <div className="googlemap_frame">
      <div className="row routefilters routeMap">
        <div className="col-sm-6 seleROute">
          <button
            className={`OptimizeRouteBtn ${
              activeButton === "Selected" ? "active" : ""
            }`}
            onClick={SelectedRoute}
          >
            Selected Route
          </button>
        </div>
        <div className="col-sm-6 seleROute">
          <button
            className={`OptimizeRouteBtn ${
              activeButton === "All" ? "active" : ""
            }`}
            onClick={AllRoute}
          >
            All Routes
          </button>
        </div>
      </div>
      <Map
        google={google}
        center={originalCoordinates}
        initialCenter={originalCoordinates}
        zoom={zoom}
        ref={mapRef}
        className="mapRadius"
      >
        {ToggleRoute?.map((marker, index) => (
          <Marker
            key={index}
            position={{ lat: marker.lat, lng: marker.lng }}
            icon={{
              url: `data:image/svg+xml,${encodeURIComponent(
                renderToString(
                  <FaMapMarkerAlt color={marker.tech_color_code} size={30} />
                )
              )}`,
              scaledSize: new google.maps.Size(30, 30),
            }}
            label={{
              text: `${marker.position ? marker.position : marker.name}`,
              color: "black",
              fontSize: "14px",
              className: "marker-label",
              fontWeight: "bold",
            }}
            onClick={onMarkerClick}
            name={marker}
          />
        ))}
        <InfoWindow
          marker={activeMarker}
          visible={activeMarker !== null}
          onClose={onClose}
        >
          <div className="selectedPlace">
            <h5>
              {selectedPlace?.name &&
                `${selectedPlace?.name?.name} ${selectedPlace?.name?.last_name}`}
            </h5>
            <hr />
            <p>
              <span>Pool :</span> {selectedPlace?.name?.totalPool}
            </p>
            {selectedPlace?.name?.position && (
              <p>
                <span>Position :</span> {selectedPlace?.name?.position}
              </p>
            )}
            {/* {selectedPlace?.name?.completedPools && (
              <p>
                <span>Completed Pools :</span>{" "}
                {selectedPlace?.name?.completedPools}
              </p>
            )} */}
            {selectedPlace?.name?.totaldistance && (
              <p>
                <span>Total Distance :</span>{" "}
                {metersToMiles(selectedPlace?.name?.totaldistance).toFixed(1)}{" "}
                miles
              </p>
            )}
            {selectedPlace?.name?.totaltime && (
              <p>
                <span>Total Time :</span>{" "}
                {secondsToMinutes(selectedPlace?.name?.totaltime).toFixed(1)}{" "}
                minutes
              </p>
            )}
          </div>
        </InfoWindow>
      </Map>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
})(GoogleMap);
