import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchgetAllprospect } from "../../redux/Slices/getProspect";

export default function ProspectFilter() {
  const dispatch = useDispatch();
  const [name, setName] = useState();
  const page = 1;
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(fetchgetAllprospect({ name, page }));
  };
  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12 equipmentssss prospect">
          <form
            className="myfilters tableFilters grayFilters"
            onSubmit={handleSubmit}
          >
            <input
              type="text"
              placeholder="Search Prospect"
              onChange={(e) => setName(e.target.value)}
            />
            <button type="submit">
              {" "}
              <i className="fa fa-search" aria-hidden="true" />
            </button>
          </form>
        </div>
      </div>
    </Fragment>
  );
}
