import React, { Fragment } from "react";
// import { Link } from "react-router-dom";

function ProfitHeader() {
  return (
    <Fragment>
        <div className="row customers">
            <div className="col-sm-12 equipmentssss">
                <h2>Profit Report</h2>
            </div>
        </div>
    </Fragment>
  );
}

export default ProfitHeader;
