import React from "react";
import { Fragment } from "react";
import CustomerServiceLocation from "./CustomerServiceLocation";

function CustomeraddServiceLocation() {
  return (
    <Fragment>
      <CustomerServiceLocation />
    </Fragment>
  );
}

export default CustomeraddServiceLocation;
