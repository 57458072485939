import React, { useEffect, useRef, useState } from "react";
import { Map, GoogleApiWrapper } from "google-maps-react";
import { ImLocation2 } from "react-icons/im";
import { Modal } from "antd";
import { renderToString } from "react-dom/server";
import { FaHome } from "react-icons/fa";

function GoogleMap({ MapData }) {
  const mapRef = useRef(null);
  const directionsService = new google.maps.DirectionsService();
  const [currentDirectionsRenderers, setCurrentDirectionsRenderers] = useState(
    []
  );
  const [currentMarkers, setCurrentMarkers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState([]); // Store an array for multiple pools

  // Clear previous directions and markers
  const clearPreviousDirections = () => {
    currentDirectionsRenderers.forEach((renderer) => {
      renderer.setMap(null);
    });
    setCurrentDirectionsRenderers([]);
    currentMarkers.forEach((marker) => marker.setMap(null));
    setCurrentMarkers([]);
  };

  const renderDirections = (
    origin,
    destination,
    color_code,
    technicianName,
    customerName,
    travelMode,
    address,
    markerIndex, // Add markerIndex to track the current marker
    totalMarkers // Total number of markers at the same location
  ) => {
    const request = {
      origin,
      destination,
      travelMode,
    };

    directionsService.route(request, (result, status) => {
      if (status === "OK" && result.routes.length > 0) {
        const directionsRenderer = new google.maps.DirectionsRenderer({
          map: mapRef?.current?.map,
          directions: result,
          suppressMarkers: true,
        });

        const polylineOptions = {
          strokeColor: color_code,
          strokeOpacity: 0.8,
          strokeWeight: 4,
        };
        directionsRenderer.setOptions({ polylineOptions });

        setCurrentDirectionsRenderers((prev) => [...prev, directionsRenderer]);
      } else {
        console.error("Error fetching directions:", status);
      }
    });

    const markerLabel = `${markerIndex}/${totalMarkers}`;

    const destinationMarker = new google.maps.Marker({
      position: destination,
      map: mapRef.current.map,
      label: {
        text: markerLabel,
        color: "#000",
        fontWeight: "600",
        fontSize: "10px",
        fontFamily: "Poppins",
      },
      icon: {
        url: `data:image/svg+xml,${encodeURIComponent(
          renderToString(<FaHome color={color_code} size={24} />)
        )}`,
        scaledSize: new google.maps.Size(30, 30),
        anchor: new google.maps.Point(15, 30),
      },
    });

    const originMarker = new google.maps.Marker({
      position: origin,
      map: mapRef.current.map,
      label: {
        text:
          totalMarkers > 1
            ? `${customerName}  (${totalMarkers})`
            : `${customerName}`,
        color: "#000",
        fontWeight: "600",
        fontSize: "15px",
        fontFamily: "Poppins",
        className: "marker-label-origin1",
      },
      icon: {
        url: `data:image/svg+xml,${encodeURIComponent(
          renderToString(<ImLocation2 color={color_code} size={24} />)
        )}`,
        scaledSize: new google.maps.Size(30, 30),
      },
    });

    originMarker.addListener("click", () => {
      setModalContent((prevContent) => [
        ...prevContent,
        { address, firstName: customerName },
      ]); // Add the new pool details to the array
      setIsModalVisible(true);
    });

    destinationMarker.addListener("click", () => {
      setTimeout(() => {
        mapRef.current.map.setCenter(destination);
        mapRef.current.map.setZoom(10);
      }, 1000);
    });

    setCurrentMarkers((prev) => [...prev, destinationMarker, originMarker]);
  };

  const renderAllData = () => {
    const bounds = new google.maps.LatLngBounds();
    const dataArray = Array.isArray(MapData?.RouteAssignmentTechnician)
      ? MapData.RouteAssignmentTechnician
      : [];

    const locationMap = {};

    dataArray.forEach((technician) => {
      const serviceLocation = new google.maps.LatLng(
        MapData?.latitude,
        MapData?.longitude
      );
      const technicianLocation = new google.maps.LatLng(
        technician?.RouteAssignmentWaterBody?.servicelat,
        technician?.RouteAssignmentWaterBody?.servicelong
      );

      const locationKey = `${technicianLocation.lat()}_${technicianLocation.lng()}`;

      if (locationMap[locationKey]) {
        locationMap[locationKey].count++;
      } else {
        locationMap[locationKey] = { count: 1, total: 0 };
      }

      locationMap[locationKey].total++;

      bounds.extend(serviceLocation);
      bounds.extend(technicianLocation);

      renderDirections(
        technicianLocation,
        serviceLocation,
        MapData.color_code,
        MapData.first_name,
        technician?.RouteAssignmentWaterBody?.position,
        "DRIVING",
        technician?.RouteAssignmentWaterBody?.service_address,
        locationMap[locationKey].count,
        locationMap[locationKey].total
      );
    });

    mapRef.current.map.fitBounds(bounds);
  };

  useEffect(() => {
    if (mapRef.current && MapData) {
      clearPreviousDirections();
      renderAllData();
    }
  }, [MapData]);

  const handleModalOk = () => {
    setIsModalVisible(false);
    setModalContent([]); // Clear modal content on close
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setModalContent([]); // Clear modal content on cancel
  };

  return (
    <div className="googlemap_frame">
      <Map
        google={google}
        ref={mapRef}
        className="mapRadius"
        style={{ width: "100%", height: "80%" }}
        zoom={10}
      />

      <Modal
        title="Details"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        {modalContent.map((content, index) => (
          <div key={index}>
            <p className="modal-map-1">
              <span>{`Route Stop: ${content.firstName}`}</span>
            </p>
            <p className="modal-map-1">
              <span>{`Address: ${content.address}`}</span>
            </p>
            <hr />
          </div>
        ))}
      </Modal>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
})(GoogleMap);
