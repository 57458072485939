import React from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";

export default function Installedsearch() {
  return (
    <Fragment>
      <div className="row customers ">
        <div className="col-sm-5 installedItems">
          <h2>Installed Items</h2>
        </div>
      </div>
    </Fragment>
  );
}
