import React from "react";
import { Fragment } from "react";

export default function AddProductHeader() {

  return (
    <Fragment>
      <div className="row customers addTechnicianFormmmss">
        <div className="col-sm-12 ">
          <h2>Add User</h2>
        </div>
      </div>
    </Fragment>
  );
}
