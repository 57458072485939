import React from 'react'
import { Fragment } from 'react'

function WorkorderHeader() {
    return (
        <Fragment>
            <div className='row customers workorderspage'>
                <div className='col-sm-12 '>
                    <h2>Add Work order</h2>
                </div>
            </div>
        </Fragment>
    )
}

export default WorkorderHeader