import React from "react";
import { Fragment } from "react";

export default function EmailHeaders() {
  return (
    <Fragment>
      <div className="col-sm-5 ">
        <h2>Email Setting</h2>
      </div>
    </Fragment>
  );
}
