import React from "react";
import { Fragment } from "react";

export default function EditShoppingHeader() {
  return (
    <Fragment>
      <div className="row customers shopHeader aboveHEade">
        <h2>Edit Shopping List</h2>
      </div>
    </Fragment>
  );
}
