import React from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";

export default function LabourReportDetailSkippedSearch() {
  return (
    <Fragment>
      <div className="row customers ">
        <div className="col-sm-12 ">
          {/* <h2>Aaron Adams</h2> */}
        </div>
      </div>
    </Fragment>
  );
}
